// Turbo
import '@hotwired/turbo-rails'

// Disable Turbo drive for navigation
// https://turbo.hotwired.dev/handbook/drive#disabling-turbo-drive-on-specific-links-or-forms
// eslint-disable-next-line no-undef
Turbo.session.drive = false

// Custom Turbo stream actions
import { setupMorph } from '@/turbo_stream_actions/morph'
setupMorph()

import { setupRedirect } from '@/turbo_stream_actions/redirect'
setupRedirect()

// Stimulus Controllers
import '@/controllers/sign_up_in'
